import GameReborn from "../GameReborn";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import Peer from "peerjs";

const { useState } = require("react")

export default function PeerMultiplayerGame(props) {
    const { gameId } = useParams();
    const [group_setup, setGroup_setup] = useState({
        options: false,
        selectedDeck: false,
        tokens: [],
        deckSizeAtStart: 100
    })
    const [isGameReady, setIsGameReady] = useState(false)

    const [board, setBoard] = useState([])
    const [deck, setDeck] = useState([])
    const [lifepoints, setLifepoints] = useState(0)
    const [group_graveyard, setGroup_graveyard] = useState({
        graveyard: [],
        lastMilledToken: false,
        millCount: 0
    })
    const [group_newTurn, setGroup_newTurn] = useState({
        turnNumber: 0,
        cardsToCast_fromLibrary: [],
        cardsToCast_fromGraveyard: [],
        cardsToCast_fromHand: [],
        cardsToCast_milestone: []
    })
    const [group_other, setGroup_other] = useState({
        hand: [],
        showTopCard: false,
        attackers: [],
        showAttackers: false
    })

    const peerInstance = useRef(null)
    const playersRef = useRef([])
    const [playerCount, setPlayerCount] = useState(0)
    const hostId = "against-the-horde-ID-"

    const [connectionMessage, setConnectionMessage] = useState("Connecting...")

    useEffect(() => {
        if (peerInstance.current == null) {
            iniPeer()
        }
    }, [])

    const iniPeer = () => {
        if (!gameId) {
            setConnectionMessage("Incorrect game url")
            return
        }
        const peer = new Peer({
            config: {'iceServers': [
                {
                  urls: "turn:global.relay.metered.ca:80",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turn:global.relay.metered.ca:80?transport=tcp",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turn:global.relay.metered.ca:443",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
                {
                  urls: "turns:global.relay.metered.ca:443?transport=tcp",
                  username: "0eaf39f8d62392b209719b97",
                  credential: "w0pnT9gUjSUYdGMb",
                },
            ]} /* Sample servers, please use appropriate ones */
          });
        
        peer.on('open', (id) => {
            console.log('My peer ID is: ' + id);
            playersRef.current.push({connection: null, id: id})
            setPlayerCount(playersRef.current.length)

            // Calling host
            call(hostId + gameId)
        });

        peerInstance.current = peer;
    }

    const call = (peerId) => {
        let conn = peerInstance.current.connect(peerId);
        conn.on("open", () => {
            playersRef.current.push({connection: conn, id: conn.peer})
            setPlayerCount(playersRef.current.length)
        })
        conn.on("close", () => {
            if (conn.peer == hostId + gameId) {
                console.log("host left")
                peerInstance.current.destroy()
                playersRef.current = []
                setIsGameReady(false)
                setConnectionMessage("Host currently not playing")
                iniPeer()
                setTimeout(() => {
                    reconnect()
                }, 1000)
            } else {
                playersRef.current.splice(playersRef.current.findIndex(a => a.id == conn.peer) , 1)
            }
            setPlayerCount(playersRef.current.length)
        })
        conn.on("data", (data) => {
            console.log(data)
            if (data.playersIdArray) {
                data.playersIdArray.forEach((id) => {
                    let index = playersRef.current.findIndex(a => a.id == id) 
                    if (index == -1) {
                        console.log("calling " + id)
                        call(id)
                    }
                })
            } else {
                handleGameData(data)
            }
        })
    }

    const handleGameData = (data) => {
        let gameData = data.gameData
        console.log(data)
        if (gameData.board) {
            setBoard(gameData.board)
        }
        if (gameData.deck) {
            setDeck(gameData.deck)
        }
        if (gameData.group_graveyard) {
            setGroup_graveyard(gameData.group_graveyard)
        }
        if (gameData.group_newTurn) {
            setGroup_newTurn(gameData.group_newTurn)
        }
        if (gameData.group_other) {
            setGroup_other(gameData.group_other)
        }
        if (gameData.group_setup) {
            setIsGameReady(true)
            setGroup_setup(gameData.group_setup)
        }
        if (gameData.lifepoints) {
            setLifepoints(gameData.lifepoints)
        }
    }

    const reconnect = () => {
        if (playersRef.current.findIndex(a => a.id == hostId + gameId) == -1) {
            call(hostId + gameId)
            setTimeout(() => {
                reconnect()
            }, 1000)
        }
    }

    if (!isGameReady) {
        return(<div style={{height: 100 + 'vh'}} class="d-flex align-items-center justify-content-center"><h2>{connectionMessage}</h2></div>)
    }

    if (isGameReady) {
        return (
            <GameReborn selectedDeck={group_setup.selectedDeck}
                setIsPlaying={props.setIsPlaying}
                options={group_setup.options}
                cloudValues={{
                    group_setup: group_setup,
                    board: board,
                    deck: deck,
                    lifepoints: lifepoints,
                    group_graveyard: group_graveyard,
                    group_newTurn: group_newTurn,
                    group_other: group_other
                }}
                playerCount={playerCount - 1}
            >
            </GameReborn>
        )
    }
}