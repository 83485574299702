import React from "react"
import './Commons.css'
import './Foil.css'
import { BrowserRouter, Route, Link } from "react-router-dom"
import { NavLink, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut, sendPasswordResetEmail } from 'firebase/auth'
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db, auth } from '../../firebase';
import Collapse from 'react-bootstrap/Collapse'
import BootstrapNavbar from 'react-bootstrap/Navbar'
import PatreonIcon from '../../assets/Icons/patreon.png'
import DiscordIcon from '../../assets/Icons/discord.png'
import DeckEditorIcon from '../../assets/Icons/edit.png'
import ThanksIcon from '../../assets/Icons/coeur.png'
import useGoogleSheets from 'use-google-sheets';

export function Button(props) {
    return (
        <button class={"default-button blurred-background" + (props.enabled ? " enabled" : "") + (props.isPurple ? " purple" : "") + (props.classes ? props.classes : "")} onClick={props.clicked} id={props.id ? props.id : ""}>
            {props.text}
        </button>
    )
}

export function BigButton(props) {
    return (
        <button class={"big-button" + (props.isPurple ? " purple" : "") + (props.classes ? props.classes : "")} onClick={props.clicked}>
            {props.text}
        </button>
    )
}

export function SelectableElement(props) {
    return (
        <button class={"selectable-element" + (props.selected ? " selected" : "")} onClick={props.onClick}>
            {props.text}
        </button>
    )
}

export function Navbar(props) {
    const [showThanks, setShowThanks] = useState(false)
    const [showLogin, setShowLogin] = useState(false)
    const [open, setOpen] = useState(props.showNavbarByDefault == undefined ? false : props.showNavbarByDefault)
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const auth = getAuth();
        const userData = JSON.parse(sessionStorage.getItem("userData"))

        if (userData == undefined) {
            setIsLoggedIn(false)
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    setIsLoggedIn(true)
                } else {
                    setIsLoggedIn(false)
                }
            });
        } else {
            setIsLoggedIn(true)
        }
    }, [])

    return (
        <BootstrapNavbar expand="lg" variant="dark" className={props.classes ? props.classes : ""}>
            <button onClick={() => setOpen(!open)}
                aria-controls="example-collapse-text"
                aria-expanded={open}
                class="navbar-toggler-button navbar-toggler blurred-background" >
                <span class="navbar-toggler-icon"></span>
            </button>

            <Collapse className="navbar-collapse" in={open} dimension="width">
                <div>
                    <div class="main-navbar">
                        <div class="logo">
                            <img src={require('./../../assets/appIcon.png')} class="shadowed" />
                            <h1>Against the Horde</h1>
                        </div>

                        <div class='main-links'>
                            <h4>MENU</h4>
                            <Link to="/" className={"link" + (props.selected == "Home" ? " selected" : "")}>Home</Link>

                            <Link to="/yourdecks" className={"link" + (props.selected == "Collection" ? " selected" : "") + (!isLoggedIn ? " disabled" : "")}>Your Decks</Link>

                            <Link to="/decks" className={"link" + (props.selected == "Browser" ? " selected" : "")}>Explore decks</Link>
                        </div>

                        <div class='mt-auto'>
                            <h4>MORE</h4>
                            <a onClick={() => { setShowThanks(true) }} class="link-discord link">
                                <img class="icon" src={ThanksIcon} />
                                Thanks
                            </a>
                            <a href="https://discord.com/invite/wzm7bu6KDJ" class="link">
                                <img class="icon" src={DiscordIcon} />
                                Join the discord
                            </a>
                            <a href="https://www.patreon.com/user?u=91929461" class="link-patreon link">
                                <img class="icon" src={PatreonIcon} />
                                Support the project
                            </a>
                        </div>

                        <Login_User_Panel setShowLogin={() => { setShowLogin(true) }} />
                        {
                            showLogin ? (
                                <div>
                                    <LoginPanel />
                                    <BigButton clicked={() => { setShowLogin(false) }} text="Return" isPurple={true} classes=" login-return-button" />
                                </div>
                            ) : (<div></div>)
                        }
                        {
                            showThanks ? (<ThanksPanel clicked={() => { setShowThanks(false) }} />) : (<div></div>)
                        }
                    </div>

                </div>
            </Collapse>
        </BootstrapNavbar>
    )

    function Login_User_Panel(props) {
        const navigate = useNavigate();
        const [isLogin, setIsLogin] = React.useState(false)
        const [user, setUser] = React.useState(false)

        const { data, loading, error } = useGoogleSheets({
            apiKey: "AIzaSyC1IFQjAzoPL7xKyyitEc7uK3_3P0Ql6bU",
            sheetId: "1S46M2fgGZ1zVuxLLeCV1wUlCJ0nMZNDvtCD_2JTFp9s",
            sheetsOptions: [{ id: 'patreons-name', headerRowIndex: 1 }],
        });

        function handleLogout() {
            const auth = getAuth();
            signOut(auth).then(() => {
                // Sign-out successful.
                window.location.reload(false)
                sessionStorage.removeItem("userData")
                console.log("Signed out successfully")
            }).catch((error) => {
                // An error happened.
            });
        }

        useEffect(() => {
            const userData = JSON.parse(sessionStorage.getItem("userData"))

            if (userData != undefined) {
                setIsLogin(true)
            }

            const auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    // User is signed in, see docs for a list of available properties
                    // https://firebase.google.com/docs/reference/js/firebase.User
                    const uid = user.uid;
                    // ...
                    console.log("uid", uid)
                    setIsLogin(true)
                    setUser(user)
                    if (userData == undefined) {
                        sessionStorage.setItem("userData", JSON.stringify({
                            uid: uid,
                            decks: undefined,
                            shouldUpdateDecks: false,
                            userCollection: undefined
                        }))
                    }
                } else {
                    // User is signed out
                    // ...
                    console.log("user is logged out")
                    setIsLogin(false)
                    setUser(false)
                    sessionStorage.removeItem("userData")
                }
            });
        }, [])


        if (loading == false) {
            if (error == null) {
                loadPatreonsIfNeeded()
            } else {
                console.log(error)
            }
        }

        async function loadPatreonsIfNeeded() {
            const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))

            if (patreonData == undefined) {
                console.log("Call sheet")
                const sheetData = data[0]
                const names = sheetData.data[0].value
                const patreonsLvl = sheetData.data.slice(1, sheetData.data.length)

                sessionStorage.setItem("patreonData", JSON.stringify({
                    names: names,
                    patreonsLvl: patreonsLvl
                }))
            }
        }

        if (isLogin) {
            return (
                <div class="login blurred-background">
                    <div class="login-username">
                        <UserName uid={user.uid} displayName={user.displayName} />
                    </div>
                    <div>
                        <Button text="Log out" isPurple={true} clicked={handleLogout} />
                    </div>
                </div>
            )
        }

        return (
            <div class="login blurred-background">
                <p class="mb-2">Login to play with other decks, create you own and share them.</p>
                <div>
                    <Button text="Login" isPurple={true} clicked={() => { props.setShowLogin() }} />
                </div>
            </div>
        )
    }

    function ThanksPanel(props) {
        const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))
        let names = ""
        if (patreonData != undefined) {
            names = patreonData.names
        }

        return (
            <div class="fullscreen-blurred-background">
                <div class='thanks-panel shadowed'>
                    <h2>Thanks to my Patreon</h2>
                    <p>{names}<br /><br /></p>

                    <p>Thanks to everyone who played the iOS app. And an even bigger thanks to those who subscribed to it and helped me cover the cost.</p>
                    <p>Thanks to my playgroup for testing the app since its early draft.</p>
                    <p>Heart icon by Nur syifa fauziah. Deck edit icon by Pixel perfect.</p>

                    <br /><h2>Download links</h2>
                    <p>Links to the iOS app for Against the Horde and for my Planechase app.</p>
                    <span>
                        <div>
                            <h1>Against the Horde</h1>
                            <a href="https://apps.apple.com/us/app/against-the-horde/id1631351942"><img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" /></a>
                        </div>
                        <div>
                            <h1>Planechase Companion</h1>
                            <a href="https://apps.apple.com/us/app/planechase-companion/id6445894290"><img src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg" /></a>
                        </div>
                    </span>

                    <Button text="Return" clicked={props.clicked} />
                </div>
            </div>
        )
    }

    function LoginPanel() {
        const [option, setOption] = React.useState(1)
        const navigate = useNavigate()
        const [errorMessage, setErrorMessage] = React.useState("")

        function submit(event) {
            event.preventDefault();

            if (option === 1) {
                signIn()
            } else if (option === 2) {
                signUp()
            } else if (option === 3) {
                forgot()
            }
        }

        async function signIn() {
            let mail = document.getElementById("email").value
            let password = document.getElementById("password").value
            setErrorMessage("")

            const auth = getAuth();

            signInWithEmailAndPassword(auth, mail, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    window.location.reload(false)
                    console.log(user);
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        async function signUp() {
            let mail = document.getElementById("email").value
            let name = document.getElementById("pseudo").value
            let password = document.getElementById("password").value
            let repeat_password = document.getElementById("repeat-password").value
            if (password != repeat_password) {
                setErrorMessage("Password doesn't match")
                return
            }
            setErrorMessage("Please wait ...")

            const auth = getAuth();

            await createUserWithEmailAndPassword(auth, mail, password)
                .then(async (userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                    console.log(user);

                    // Set username
                    updateProfile(auth.currentUser, {
                        displayName: name
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    });

                    await setDoc(doc(db, "usersCollection", user.uid), {
                        collection: [
                            "sOnCKduUDPtELqms0SXX",
                            "cJqFVGRIpDiHqOxpY8sq",
                            "Qgkkg2iozPrqds1jOFaL",
                            "WWAzdmLRmhtfVWGjMMP7",
                            "cTCVrZxuUJuqyiMNRj88",
                            "xNXilrTfTOj6ajmfRKbl",
                            "zouNmM7ZxNdxnp5pBj4c"
                        ]
                    }).then(() => {
                        // Profile updated!
                        window.location.reload(false)
                    }).catch((error) => {
                        setErrorMessage(error.message)
                    });
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        function forgot() {
            let mail = document.getElementById("email").value

            sendPasswordResetEmail(auth, mail)
                .then(() => {
                    setErrorMessage("An email has been sent to " + mail)
                })
                .catch((error) => {
                    setErrorMessage(error.message)
                });
        }

        return (
            <div class="login-panel fullscreen-blurred-background">
                <div className='container'>
                    <header>
                        <div className={'header-headings ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>
                            <span>Sign in to your account</span>
                            <span>Create an account</span>
                            <span>Reset your password</span>
                        </div>
                    </header>
                    <ul className='options d-flex justify-content-between w-100 p-0'>
                        <li className={option === 1 ? 'active' : ''} onClick={() => setOption(1)}>Sign in</li>
                        <li className={option === 2 ? 'active' : ''} onClick={() => setOption(2)}>Sign up</li>
                        <li className={option === 3 ? 'active' : ''} onClick={() => setOption(3)}>Forgot</li>
                    </ul>
                    <p class="text-start">{errorMessage}</p>
                    <Form option={option} />
                </div>
            </div>
        )

        function Form({ option }) {
            return (
                <form className='account-form' onSubmit={submit}>
                    <div className={'account-form-fields ' + (option === 1 ? 'sign-in' : (option === 2 ? 'sign-up' : 'forgot'))}>
                        <input class="blurred-background" id='email' name='email' type='email' placeholder='E-mail' required />
                        <input class="blurred-background" id='pseudo' name='pseudo' type='text' placeholder='Name' maxlength="15" required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} />
                        <input class="blurred-background" id='password' name='password' type='password' placeholder='Password' required={option === 1 || option === 2 ? true : false} disabled={option === 3 ? true : false} />
                        <input class="blurred-background" id='repeat-password' name='repeat-password' type='password' placeholder='Repeat password' required={option === 2 ? true : false} disabled={option === 1 || option === 3 ? true : false} />
                    </div>
                    <button className='btn-submit-form' type='submit'>
                        {option === 1 ? 'Sign in' : (option === 2 ? 'Sign up' : 'Reset password')}
                    </button>
                </form>
            )
        }
    }
}

export function LandscapeOnly(props) {
    return (
        <div>
            <div class="landscape-only">
                {props.component}
            </div>
            <div class="fullscreen portrait-message">
                <p>This app only works in landscape mode.</p>
            </div>
        </div>
    )
}

export function UserName(props) {
    function patreonLvlForUid(uid) {
        const patreonData = JSON.parse(sessionStorage.getItem("patreonData"))

        if (patreonData == undefined) {
            return "patreon-0"
        }

        const patreonsArray = patreonData.patreonsLvl

        if (patreonsArray == undefined) {
            return "patreon-0"
        }

        let patreonLvl = 0

        for (const i in patreonsArray) {
            if (patreonsArray[i].key == uid) {
                patreonLvl = patreonsArray[i].value
            }
        }

        return ("patreon-" + patreonLvl)
    }

    return (
        <p class={"patreon m-0 " + patreonLvlForUid(props.uid)}>{props.displayName}</p>
    )
}

export function CardIMG(props) {
    const cardId = props.cardId ? props.cardId : props.card.id
    const cardName = props.card.cardName
    const [isHolding, setIsHolding] = useState(false)
    let cardBackUrl = false
    const [showCardBack, setShowCardBack] = useState(props.card.showFront == undefined ? false : !props.card.showFront)

    let cardUrl = "https://api.scryfall.com/cards/" + cardId + "?format=img&version=normal"
    const cardUrls = cardId.split("://:")

    useEffect(() => {
        if (props.forcedShowBack != undefined) {
            console.log(cardName)
            console.log(showCardBack)
            setShowCardBack(props.forcedShowBack)
        }
    }, [props.forcedShowBack]);

    const setImgurSize = (mystr) => {
        var idx = mystr.lastIndexOf(".")
        if (idx > -1) {
            var outval = mystr.substr(0, idx) + "l" + mystr.substr(idx)
            return outval
        }
        return mystr
    }

    if (cardUrls[0].includes("https://i.imgur.com")) {
        cardUrl = setImgurSize(cardUrls[0])
    } else if (cardUrls[0].includes("D::")) {
        cardUrl = "https://media.discordapp.net/attachments/1127961672225673256/" + cardUrls[0].slice(3) + "?width=488&height=680"
    } else if (cardName && cardName.includes(" // ")) {
        // check for mdfc
        cardBackUrl = cardUrl + "&face=back"
    }

    if (cardUrls.length == 2) {
        if (cardUrls[1].includes("https://i.imgur.com")) {
            cardBackUrl = setImgurSize(cardUrls[1])
        } else if (cardUrls[1].includes("D::")) {
            cardBackUrl = "https://media.discordapp.net/attachments/1127961672225673256/" + cardUrls[1].slice(3) + "?width=488&height=680"
        }
    }

    /*
        https://cdn.discordapp.com/attachments/1127961672225673256/1201621894558912632/Easterling_1.png?width=488&height=680
        https://cdn.discordapp.com/attachments/1127961672225673256/1208053547472519199/Dwarven_city_B.png?width=488&height=680
        https://cdn.discordapp.com/attachments/1127961672225673256/1208053547472519199/Dwarven_city_B.png?ex=65e1e28a&is=65cf6d8a&hm=9f811f0eb35bc6f3c0a82f93d4b14933cf216a96bd2d8e02ebd995afdb08f66f&


        const alternativeTokensData = JSON.parse(sessionStorage.getItem("alternativeTokensData"))
        if (alternativeTokensData != undefined) {
            alternativeTokensData.availableCardsReplacer.forEach(replacer => {
                if (replacer.cardId == cardId) {
                    cardUrl = replacer.cardUrl
                }
            });
        }
    */

    let timeOutId
    const mouseHold = e => {
        //e.stopPropagation()
        timeOutId = setTimeout(function () {
            // You are now in a hold state, you can do whatever you like!
            console.log("Hold")
            setIsHolding(true)
        }, 300);
    }

    const imgClicked = e => {
        if (timeOutId != undefined) {
            clearTimeout(timeOutId)
            console.log("clearing tiemout")
        }
        if (isHolding) {
            setIsHolding(false)
        } else {
            console.log("Clicked complete")
            if (props.onClick) {
                props.onClick()
            }
        }
    }

    if (isHolding) {
        return (
            <div class="w-100 h-100 position-relative" onClick={imgClicked} onMouseDown={mouseHold}>
                <div class={props.classes + " iphone-prevent-zoom position-relative " + ((props.showBack == undefined ? showCardBack : props.showBack) && cardBackUrl ? "show-card-back" : "")}>
                    {cardBackUrl ? (<img src={cardBackUrl} class="card-image shadowed card-back" />) :
                        (<div></div>)
                    }
                    <img src={cardUrl} class="card-image shadowed card-front" />
                </div>
                {cardBackUrl ? (
                    <div class={"f-flex flex=row card-back-button " + (props.isSpectating ? "hidden" : "")}>
                        {props.flipOnlyOne && (
                            <button class="default-button mx-auto" onClick={props.flipOnlyOne}>
                                <img src={require("../../assets/Icons/repeat-one.png")} />
                            </button>)
                        }
                        {props.flipAll ? (
                            <button class="default-button mx-auto" onClick={() => { props.flipAll(); setShowCardBack(!showCardBack) }}>
                                <img src={require("../../assets/Icons/repeat.png")} />
                            </button>
                        ) : (<button class="default-button mx-auto" onClick={() => { setShowCardBack(!showCardBack) }}>
                            <img src={require("../../assets/Icons/repeat.png")} />
                        </button>)
                        }
                    </div>
                ) : (<div></div>)
                }
                <div class="fadeIn zoom-background noScrollbar">
                    <img src={require("../../assets/Icons/rotate.png")} class="zoom-rotate-toggle" />
                    <img src={showCardBack ? cardBackUrl : cardUrl} class={"shadowed zoomingIn"} />
                </div>
            </div >
        )
    }



    /*
        <div onClick={imgClicked}  class="w-100 h-100 position-relative">
            <img src={cardUrl} class={props.classes} />
            <div class="fullscreen-blurred-background fadeIn zoom-background" >
                <img src={cardUrl} class={props.classes + " shadowed zoomingIn"} onMouseDown={mouseHold} />
            </div>
        </div>*/


    if (props.isFoil) {
        const uniqueId = Math.random().toString(36).substr(2, 9);
        //let timeoutId

        const mouseMove = e => {
            const foilEffect = document.getElementById(uniqueId + "_foil_front");
            //const cardStacks = document.getElementById(uniqueId + "_card-stacks");

            var l = e.nativeEvent.offsetX;
            var t = e.nativeEvent.offsetY;
            var h = foilEffect.clientHeight;
            var w = foilEffect.clientWidth;
            var px = Math.abs(Math.floor(100 / w * l) - 100);
            var py = Math.abs(Math.floor(100 / h * t) - 100);
            /*
                        var lp = (50 + (px - 50) / 1.5);
                        var tp = (50 + (py - 50) / 1.5);
                        var ty = ((tp - 50) / 2) * -1;
                        var tx = ((lp - 50) / 1.5) * 0.5;
            */
            //clearTimeout(timeoutId)
            foilEffect.style.transition = "none"
            foilEffect.style.backgroundPosition = px + "% " + py + "%"
            /*
                        cardStacks.style.transition = "none"
                        cardStacks.style.transform = 'rotateX(' + ty + 'deg) rotateY(' + tx + 'deg)'
            */
            // timeoutId = setTimeout(() => {
            /*foilEffect.style.animation = "holoGradient 15s ease infinite"*/
            //foilEffect.style.transition = "background-position 1s ease"
            // foilEffect.style.backgroundPosition = "70% 70%"


            /*
                            cardStacks.style.transition = "transform 1s ease"
                            cardStacks.style.transform = 'rotateX(0) rotateY(0)'
                            */
            //}, 1000)

            if (cardBackUrl) {
                const foilEffectBack = document.getElementById(uniqueId + "_foil_back");
                foilEffectBack.style.transition = "none"
                foilEffectBack.style.backgroundPosition = px + "% " + py + "%"
            }
        }

        return (
            /*<div style={{ perspective: '500vh' }}>*/
            /*
            <div class={props.classes + " card-stacks iphone-prevent-zoom"} onClick={imgClicked} onMouseDown={mouseHold} onMouseMove={mouseMove} >
                <img src={cardUrl} />

                <div id={uniqueId + "_foil"} class="foil-effect">

                </div>
            </div>*/
            /*</div>*/

            <div class="w-100 h-100 position-relative">
                <div onClick={imgClicked} onMouseDown={mouseHold} onMouseMove={mouseMove} class={props.classes + " iphone-prevent-zoom position-relative " + ((props.showBack == undefined ? showCardBack : props.showBack) && cardBackUrl ? "show-card-back" : "")}>
                    {cardBackUrl && (
                        <div class={" card-stacks card-image shadowed card-back"} >
                            <img src={cardBackUrl} class="w-100 h-100" />

                            <div id={uniqueId + "_foil_back"} class="foil-effect w-100 h-100">

                            </div>
                        </div>
                    )}
                    <div class={" card-stacks card-image shadowed card-front"} >
                        {/*
                        <img src={cardUrl} class="w-100 h-100" />
                        <div id={uniqueId + "_foil_front"} class="foil-effect-new w-100 h-100">
                            <img src={cardUrl} class="w-100 h-100 foil-effect-mask-new" />
                        </div>

                */}

                        <img src={cardUrl} class="w-100 h-100" />
                        <div id={uniqueId + "_foil_front"} class="foil-effect w-100 h-100">
                        </div>

                    </div>

                </div>
                {cardBackUrl && (
                    <div class={"f-flex flex=row card-back-button " + (props.isSpectating ? "hidden" : "")}>
                        {props.flipOnlyOne && (
                            <button class="default-button mx-auto" onClick={props.flipOnlyOne}>
                                <img src={require("../../assets/Icons/repeat-one.png")} />
                            </button>)
                        }
                        {props.flipAll ? (
                            <button class="default-button mx-auto" onClick={() => { props.flipAll(); setShowCardBack(!showCardBack) }}>
                                <img src={require("../../assets/Icons/repeat.png")} />
                            </button>
                        ) : (<button class="default-button mx-auto" onClick={() => { setShowCardBack(!showCardBack) }}>
                            <img src={require("../../assets/Icons/repeat.png")} />
                        </button>)
                        }

                    </div>
                )}
            </div >
        )
    }

    return (
        <div class="w-100 h-100 position-relative">
            <div onClick={imgClicked} onMouseDown={mouseHold} class={props.classes + " iphone-prevent-zoom position-relative " + ((props.showBack == undefined ? showCardBack : props.showBack) && cardBackUrl ? "show-card-back" : "")}>
                {cardBackUrl && (<img src={cardBackUrl} class="card-image shadowed card-back" />)}
                <img src={cardUrl} class="card-image shadowed card-front" />
            </div>
            {cardBackUrl && (
                <div class={"f-flex flex=row card-back-button " + (props.isSpectating ? "hidden" : "")}>
                    {props.flipOnlyOne && (
                        <button class="default-button mx-auto" onClick={props.flipOnlyOne}>
                            <img src={require("../../assets/Icons/repeat-one.png")} />
                        </button>)
                    }
                    {props.flipAll ? (
                        <button class="default-button mx-auto" onClick={() => { props.flipAll(); setShowCardBack(!showCardBack) }}>
                            <img src={require("../../assets/Icons/repeat.png")} />
                        </button>
                    ) : (<button class="default-button mx-auto" onClick={() => { setShowCardBack(!showCardBack) }}>
                        <img src={require("../../assets/Icons/repeat.png")} />
                    </button>)
                    }

                </div>
            )}
        </div >
    )
}

export function DeckArt(props) {
    const artId = props.artId

    let artUrl = "https://api.scryfall.com/cards/" + artId + "?format=img&version=art_crop"
    if (artId.includes("https://i.imgur.com")) {
        artUrl = artId
    }
    if (artId.includes("D::")) {
        artUrl = "https://media.discordapp.net/attachments/1127961672225673256/" + artId.slice(3)
    }

    return (
        <img src={artUrl} class={props.classes} />
    )
}

export function Footer() {
    return (
        <div class="footer">
            <div class="container py-4">
                <p class="">Wizards of the Coast, Magic: The Gathering, and their logos are trademarks of Wizards of the Coast LLC in the United States and other countries. © 1993-2024 Wizards. All Rights Reserved.</p>
                <p class="">Against the Horde is an unofficial Fan Content permitted under the Fan Content Policy. Not approved/endorsed by Wizards. Portions of the materials used are property of Wizards of the Coast. ©Wizards of the Coast LLC.</p>
                <p>Homepage art by Cristi Balanescu ©Wizards of the Coast LLC</p>
                <p>For any question or things you'd like to see added to the app, please feel free to reach me on Discord.</p>
                <p>Current version: 2.3</p>
            </div>
        </div>
    )
}

export function WithHelp(props) {
    return (
        <div class="with-help d-flex flex-row justify-content-between m-0">
            {props.component}
            <h1 class="m-0 d-flex align-items-top justify-content-end">?
                <p class="shadowed">{props.help}</p>
            </h1>
        </div>
    )
}

export function ClickWithFeedBack(props) {
    const [clicked, setClicked] = useState(false)
    let timeOut

    return (
        <div onClick={() => {
            setClicked(true)
            clearTimeout(timeOut)
            timeOut = setTimeout(() => { setClicked(false) }, 400);
        }} class={"click-with-feedback" + (clicked ? " clicked" : "")}>
            {props.component}
        </div>
    )
}

export default Button

